.promo-page-container {
  width: 100%;
}

.promo-page {
  height: 100%;
  padding-top: 56px;
  overflow: auto;
  background-color: var(--gray-background);

  @media (min-width: 768px) {
    padding-top: 128px;
    background-color: var(--white);
  }

  &__content {
    min-height: calc(100% - 292px);
    margin-top: 20px;

    @media (min-width: 768px) {
      padding: 16px 76px 48px 76px;
    }
  }

  &__list-wrapper {
    flex: 1;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    padding: 0 12px 44px 12px;
  }
}

