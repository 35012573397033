.cookie-alert {
  z-index: 1000;
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;

  &__content {
    position: relative;
    display: flex;
    flex: 1;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    max-width: 1216px;
    background: var(--white);
    box-shadow: 0 2px 32px rgba(46, 0, 53, 0.24);
    border-radius: 18px;
    padding: 26px;
    margin: 0 30px 22px 30px;

    @media (max-width: 768px) {
      flex-direction: column;
      margin: 0 30px 22px 30px;
    }

    &__text {
      font-family: "Manrope-Light";
      color: var(--purple-dark-theme-text);
      max-width: 727px;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      margin: 0 21px 0 21px;

      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 22px;
        margin: 5px 0 0 0;
      }

      &-link  {
        color: var(--purple-theme);
      }
    }

    &__image {
      width: 56px;

      @media (max-width: 768px) {
        width: 44px;
      }

      &__container {
        @media (max-width: 768px) {
          position: absolute;
          align-self: flex-start;
          top: -20px;
        }
      }
    }

    &__button {
      min-width: 124px !important;
      width: 124px;
      justify-self: flex-end;

      @media (max-width: 768px) {
        max-width: none;
        width: 100%;
        margin-top: 16px;
      }
    }
  }
}
