.header-container {
  height: 56px;
  min-height: 56px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: var(--white);

  @media (min-width: 768px) {
    height: 128px;
    min-height: 128px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4px 0 16px;
  width: 100%;
  height: 56px;

  @media (min-width: 768px) {
    height: 80px;
    padding: 0 76px 0 76px;
  }

  &__local {
    display: flex;
    align-items: center;
  }

  &__logo {
    margin-right: 12px;
    display: flex;
    width: 75px;
    height: 38px;
    cursor: pointer;
  }

  &__img {
    width: 75px;
    height: 37px;
    cursor: pointer;
  }

  &-actions {
    display: flex;
    align-items: center;

    @media (min-width: 768px) {
      width: 100%;
      margin-left: 24px;
    }

    &__btn {
      position: relative;
      width: 24px;
      height: 24px;
      margin-left: 12px;
      margin-right: 12px;
      cursor: pointer;

      &-badge {
        width: 14px;
        height: 14px;
        position: absolute;
        top: -5px;
        right: -5px;
        border-radius: 50%;
        background-color: var(--purple-theme);
        text-align: center;
        font-family: "Manrope-Bold";
        font-size: 10px;
        line-height: 14px;
        color: var(--white);
      }
    }
  }

  &__search-container {
    flex: 1;
    z-index: 100;
    position: absolute;
    left: 0;
    right: 0;
    top: 56px;

    @media (min-width: 768px) {
      position: initial;
    }
  }

  &__extra {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    width: 100%;
    padding: 0 76px 0 76px;
    background-color: var(--gray-background-item);

    &__icon {
      width: 24px;
      height: 24px;
    }

    &-local {
      display: flex;
      align-items: center;
    }

    &-contact {
      display: flex;
      align-items: center;
    }

    &-location, &-language, &-contact__email,  &-contact__phone {
      display: flex;
      align-items: center;

      &-text {
        margin-left: 4px;
        font-family: "Manrope-SemiBold";
        font-size: 14px;
        line-height: 20px;
        color: var(--black-theme);
      }
    }

    &-location {
      cursor: pointer;
    }

    &-language {
      cursor: pointer;
      margin-left: 24px;

      &-menu {
        margin-top: 8px;
        z-index: 1000 !important;
        border-radius: 12px !important;
      }

      &__icon {
        margin-left: 4px;
        display: inline-flex;
        width: 18px;
        height: 18px;
      }

      &-text {
        display: flex !important;
        align-items: center;
      }
    }

    &-contact__phone {
      margin-left: 24px;
    }
  }
}
