.product-item {
  display: flex;
  position: relative;
  margin-bottom: 8px;
  margin-left: 4px;
  margin-right: 4px;
  padding: 16px;
  border-radius: 18px;
  background-color: var(--white);
  cursor: pointer;

  &__deleted {
    opacity: 0.4;
  }

  &__status {
    z-index: 10;
    position: absolute;
    top: 9px;
    left: 9px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--green-theme);

    &-deleted {
      background-color: var(--red-theme);
    }

    &-icon {
      width: 20px;
      height: 20px;
      display: flex;
    }
  }

  &-disabled {
    background-color: var(--gray-background-item);
  }

  &__image {
    width: 60px;
    height: 60px;
    border-radius: 12px;
    object-fit: cover;
  }

  &__info-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 0 0 12px;
  }

  &__info {
    flex-basis: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__name {
    display: inline-flex;
    font-family: "Manrope-Medium";
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    margin-right: 4px;
  }

  &__weight {
    display: inline-flex;
    font-family: "Manrope-Medium";
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: var(--gray-text-theme);
  }

  &__price-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 4px;
  }

  &__price {
    white-space: pre;
    font-family: "Rothek-Bold";
    font-size: 18px;
    line-height: 16px;
    margin-bottom: 4px;
  }

  &__discount {
    font-family: "Rothek-Bold";
    text-decoration: line-through;
    font-size: 12px;
    line-height: 16px;
    color: var(--red-theme);
  }

  &__quantity__value {
    font-family: "Rothek-Bold";
    font-size: 14px;
    line-height: 20px;
  }

  &__quantity-edit {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__value {
      text-align: center;
      min-width: 72px;
      padding: 0 9px 0 9px;
      font-family: "Rothek-Bold";
      font-size: 14px;
      line-height: 20px;

      @media (min-width: 768px) {
        font-size: 16px;
      }
    }

    &__btn {
      min-width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: var(--purple-theme);

      @media (min-width: 768px) {
        min-width: 32px;
        height: 32px;
      }

      &-dec {
        background-color: var(--gray-background-item);
      }

      &-icon {
        width: 12px;
        height: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (min-width: 768px) {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  &__remove {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 24px;
    height: 24px;

    &-icon {
      display: flex;
      width: 24px;
      height: 24px;
    }
  }
}
