:root {
  --black-theme: #1d1d1b;
  --purple-theme: #6516ce;
  --purple-light-theme: #dcc6df;
  --purple-dark-theme: #4c0f9e;
  --purple-dark-theme-text: #858897;
  --white: #ffffff;
  --black: #000000;
  --gray-background: #f2f3f6;

  --black-text-theme: #1d1d1b;
  --gray-text-theme: #858897;
  --gray-light-text-theme: #a0a3b5;

  --gray-background-item: #f7f8fa;

  --gray-elem: #bec2cd;
  --gray-dark-elem: #a0a3b5;
  --gray-light-elem: #e7e8ea;
  --gray-light-elem-2: #e5e5e5;

  --red-theme: #eb5757;
  --green-theme: #5bc258;
  --green-dark-theme: #33b04b;

  --telegram: #0f98dc;
  --viber: #665ca7;
  --chat: #56bb61;

  --tablet-width: 768px;
  --mobile-width: 445px;
}
