.home {
  display: flex;
  width: 100%;
  height: 100%;

  &__container {
    width: 100%;
    display: flex;
  }
}
