.search-products__container {
  background-color: var(--gray-background);
  box-shadow: 0 4px 40px 0 #AEAEAE3D;
  padding: 16px;

  @media (min-width: 768px) {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
  }
}

.search-products {
  position: relative;
  box-shadow: 0 8px 24px 0 #20202029;

  @media (min-width: 768px) {
    box-shadow: none;
    border-radius: 24px;
  }

  &__field {
    height: 56px;
    background-color: var(--white);
    border-radius: 12px;

    @media (min-width: 768px) {
      margin: 0;
      height: 56px;
      padding: 0;
      box-shadow: none;
      background-color: var(--gray-background-item);
    }
  }

  &__shadow {
    position: absolute;
    height: 100vh;
    top: 88px;
    left: 0;
    right: 0;
    opacity: 0.4;
    background-color: var(--black-theme);
  }

  &__content {
    padding: 0 12px 18px 12px;
    position: absolute;
    left: 0;
    right: 0;
    top: 86px;
    background-color: var(--gray-background);

    @media (min-width: 768px) {
      top: 62px;
      padding: 16px;
      border-radius: 24px;
    }
  }

  &__more {
    margin-bottom: 8px;
    text-align: center;
    font-family: "Rothek-Bold";
    font-size: 18px;
    line-height: 24px;
    color: var(--purple-theme);
    cursor: pointer;
  }

  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 144px);
    padding: 0 36px 92px 36px;

    @media (min-width: 768px) {
      height: 264px;
      padding: 16px;
    }

    &-title {
      font-family: "Manrope-Bold";
      font-size: 24px;
      line-height: 36px;
    }

    &-text {
      max-width: 240px;
      text-align: center;
      margin-top: 8px;
      font-family: "Manrope-Regular";
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
      color: var(--gray-text-theme)
    }

    &-btn {
      margin-top: 24px;
      width: 222px;
    }
  }

  &__divider {
    margin: 16px 0 16px 0;
    height: 1px;
    background-color: var(--gray-light-elem);
  }
}
