.button-cmp {
  min-width: 222px;
  padding: 0 24px 0 24px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  font-family: "Rothek-Bold";
  font-size: 16px;
  background-color: var(--purple-theme);
  color: var(--white);
  box-shadow: 0 4px 24px 0 #8B42933D;
  cursor: pointer;

  &-outline {
    border-radius: 16px;
    border: 2px solid var(--purple-theme);
    color: var(--purple-theme);
    background-color: transparent;
  }

  &-disabled {
    background-color: var(--purple-light-theme);
  }
}
