.footer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--purple-dark-theme);
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 14px 16px 18px 16px;
  width: 100%;

  @media (min-width: 768px) {
    padding: 24px 76px 24px 76px;
  }

  &__info {
    &-phone {
      display: flex;
      justify-content: flex-end;
      text-align: right;
      font-family: 'Rothek-Bold';
      font-size: 20px;
      line-height: 24px;
      color: var(--white);
      cursor: pointer;
    }

    &-email {
      display: flex;
      justify-content: flex-end;
      text-align: right;
      font-family: 'Manrope-SemiBold';
      font-size: 16px;
      line-height: 21px;
      color: var(--white);
      opacity: 0.7;
      cursor: pointer;
    }
  }

  &__messengers {
    display: flex;
    align-items: center;

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      margin-right: 16px;
      border-radius: 8px;
      background-color: var(--purple-theme);
      cursor: pointer;

      &-icon {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__links {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__link {
    margin-top: 15px;
    font-family: 'Manrope-SemiBold';
    font-size: 14px;
    line-height: 19px;
    color: var(--white);
    opacity: 0.7;
    cursor: pointer;

    @media (min-width: 768px) {
      margin-left: 16px;
      margin-right: 16px;
      margin-top: 0;
    }
  }
}
