.product-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 150px;
  min-width: 150px;
  max-width: 186px;
  margin-bottom: 8px;
  margin-left: 4px;
  margin-right: 4px;
  padding: 4px;
  border-radius: 18px;
  background-color: var(--white);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  user-select: none;

  @media (min-width: 768px) {
    margin-left: 14px;
    margin-right: 14px;
    margin-bottom: 24px;
  }

  &__image {
    width: 100%;
    height: 124px;
    border-radius: 18px 18px 12px 12px;
    object-fit: cover;

    @media (min-width: 768px) {
      height: 150px;
    }
  }

  &__info {
    margin: 10px 8px 4px 8px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__name {
    font-family: "Manrope-SemiBold";
    font-size: 14px;
    line-height: 18px;
  }

  &__weight {
    margin-top: 4px;
    font-family: "Manrope-Regular";
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: var(--gray-text-theme);
  }

  &__price {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: -4px;

    &-value {
      font-family: "Rothek-Bold";
      font-size: 18px;
      line-height: 16px;
    }

    &-discount {
      font-family: "Rothek-Bold";
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      text-decoration: line-through;
      color: var(--red-theme);
    }
  }

  &__quantity-edit {
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    background-color: var(--purple-theme);

    &__value {
      padding: 0 9px 0 9px;
      font-family: "Rothek-Bold";
      font-size: 16px;
      line-height: 22px;
      color: var(--white);
    }

    &__btn {
      min-width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--purple-theme);
      border-radius: 12px;

      &-icon {
        width: 24px;
        height: 24px;
      }
    }
  }
}
