@font-face {
  font-family: "Rothek-Bold";
  src: local('Rothek-Bold'),
    url('../assets/fonts/Rothek/Rothek-Bold.woff') format('woff'),
    url('../assets/fonts/Rothek/Rothek-Bold.woff2') format('woff2'),
    url('../assets/fonts/Rothek/Rothek-Bold.ttf') format('truetype'),
    url('../assets/fonts/Rothek/Rothek-Bold.eot') format('embedded-opentype');
  font-display: swap;
}

@font-face {
  font-family: "Manrope-SemiBold";
  src: local('Manrope-SemiBold'),
    url('../assets/fonts/Manrope/Manrope-SemiBold.ttf') format('truetype'),
    url('../assets/fonts/Manrope/Manrope-SemiBold.woff') format('woff'),
    url('../assets/fonts/Manrope/Manrope-SemiBold.woff2') format('woff2'),
    url('../assets/fonts/Manrope/Manrope-SemiBold.eot') format('embedded-opentype'),
    url('../assets/fonts/Manrope/Manrope-SemiBold.svg#Manrope-SemiBold') format('svg');
  font-display: swap;
}

@font-face {
  font-family: "Manrope-Bold";
  src: local('Manrope-Bold'),
    url('../assets/fonts/Manrope/Manrope-Bold.ttf') format('truetype'),
    url('../assets/fonts/Manrope/Manrope-Bold.woff') format('woff'),
    url('../assets/fonts/Manrope/Manrope-Bold.woff2') format('woff2'),
    url('../assets/fonts/Manrope/Manrope-Bold.eot') format('embedded-opentype'),
    url('../assets/fonts/Manrope/Manrope-SemiBold.svg#Manrope-Bold') format('svg');
  font-display: swap;
}

@font-face {
  font-family: "Manrope-ExtraBold";
  src: local('Manrope-ExtraBold'),
    url('../assets/fonts/Manrope/Manrope-ExtraBold.ttf') format('truetype'),
    url('../assets/fonts/Manrope/Manrope-ExtraBold.woff') format('woff'),
    url('../assets/fonts/Manrope/Manrope-ExtraBold.woff2') format('woff2'),
    url('../assets/fonts/Manrope/Manrope-ExtraBold.eot') format('embedded-opentype'),
    url('../assets/fonts/Manrope/Manrope-ExtraBold.svg#Manrope-ExtraBold') format('svg');
  font-display: swap;
}

@font-face {
  font-family: "Manrope-Regular";
  src: local('Manrope-Regular'),
    url('../assets/fonts/Manrope/Manrope-Regular.ttf') format('truetype'),
    url('../assets/fonts/Manrope/Manrope-Regular.woff') format('woff'),
    url('../assets/fonts/Manrope/Manrope-Regular.woff2') format('woff2'),
    url('../assets/fonts/Manrope/Manrope-Regular.eot') format('embedded-opentype'),
    url('../assets/fonts/Manrope/Manrope-Regular.svg#Manrope-Regular') format('svg');
  font-display: swap;
}

@font-face {
  font-family: "Manrope-Medium";
  src: local('Manrope-Medium'),
    url('../assets/fonts/Manrope/Manrope-Medium.ttf') format('truetype'),
    url('../assets/fonts/Manrope/Manrope-Medium.woff') format('woff'),
    url('../assets/fonts/Manrope/Manrope-Medium.woff2') format('woff2'),
    url('../assets/fonts/Manrope/Manrope-Medium.eot') format('embedded-opentype'),
    url('../assets/fonts/Manrope/Manrope-Medium.svg#Manrope-Medium') format('svg');
  font-display: swap;
}

@font-face {
  font-family: "Manrope-Light";
  src: local('Manrope-Light'),
    url('../assets/fonts/Manrope/Manrope-Light.ttf') format('truetype'),
    url('../assets/fonts/Manrope/Manrope-Light.woff') format('woff'),
    url('../assets/fonts/Manrope/Manrope-Light.woff2') format('woff2'),
    url('../assets/fonts/Manrope/Manrope-Light.eot') format('embedded-opentype'),
    url('../assets/fonts/Manrope/Manrope-Light.svg#Manrope-Light') format('svg');
  font-display: swap;
}
