@import 'fonts';
@import 'vars';

html, body {
  //overflow: hidden;
  height: 100vh;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;

  div {
    box-sizing: border-box;
  }

  div::-webkit-scrollbar {
    width: 3px;
    height: 3px;

    @media (min-width: 768px) {
      width: 6px;
      height: 6px;
    }
  }

  div::-webkit-scrollbar-track {
    background: transparent;
  }
}

body {
  margin: 0;
  word-break: break-word;
  color: var(--black-text-theme);
}

#root {
  width: 100%;
  height: 100%;
}
