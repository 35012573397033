.search-field {
  height: 56px;
  min-width: 252px;
  flex: 2;
  position: relative;
  border-radius: 12px;

  &__input {
    width: 100%;
  }

  &__icon {
    position: absolute;
    width: 36px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 8px;
    cursor: pointer;
  }

  input {
    font-family: "Manrope-Regular" !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    height: 56px;
    border-radius: 12px !important;
    border: none !important;
    background-color: transparent !important;

    &::placeholder {
      color: var(--gray-light-text-theme) !important;
    }
  }
}
