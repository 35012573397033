.sub-header {
  padding: 20px 16px 20px 16px;
  background-color: var(--purple-theme);
  position: relative;

  @media (min-width: 768px) {
    padding: 20px 76px 20px 76px;
  }

  &__action {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-family: 'Manrope-Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 15.4px;
    opacity: 0.7;
    color: var(--white);
    cursor: pointer;

    &-icon {
      width: 18px;
      height: 18px;
    }
  }

  &__sub-action {
    position: absolute;
    right: 16px;
    bottom: 24px;
    cursor: pointer;

    @media (min-width: 768px) {
      right: 76px;
    }
  }

  &__title {
    margin-right: 24px;
    font-family: 'Rothek-Bold';
    font-size: 24px;
    line-height: 26.4px;
    color: var(--white);
  }
}
